import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

const Header: React.FunctionComponent = () => {
  const router = useRouter()

  return (
    <>
      {(router.asPath === '/' || router.asPath === '/login-error') && (
        <header
          className={'bg-coolGray-800 flex items-center justify-center rounded-b-2xl shadow-xl'}>
          <div className="max-w-screen-lg w-full">
            <nav className="flex items-center justify-between h-16">
              <Link
                href="/"
                className="px-3 py-2 text-lg font-semibold text-white tracking-wide opacity-100">
                ニューヨーク育英学園
              </Link>
            </nav>
          </div>
        </header>
      )}
    </>
  )
}

export default Header
