import React from 'react'
import { useRouter } from 'next/router'
import { useUser } from '../lib/hooks'

const Footer: React.FunctionComponent = () => {
  const { user, isFinished } = useUser({})
  const router = useRouter()

  return (
    <>
      {(router.asPath === '/' || router.asPath === '/login-error') && (
        <footer className={'flex-none w-full bg-coolGray-800'}>
          <span className="text-white text-center block py-2 text-xs">
            &copy; Japanese Children&apos;s Society, Inc.
          </span>
        </footer>
      )}
    </>
  )
}

export default Footer
