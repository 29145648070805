import React, { useEffect, useState } from 'react'
import { useUser } from 'lib/hooks'
import { Client, query as q } from 'faunadb'

import Link from 'next/link'
import { useRouter } from 'next/router'

const Header: React.FunctionComponent = () => {
  const { isFinished, user } = useUser({})
  const [isNavOpened, setIsNavOpened] = useState(false)
  const router = useRouter()
  const [meData, setMeData] = useState(null)
  const [studentsData, setStudentsData] = useState(null)
  const client = user?.faunaSecret
    ? new Client({
        domain: 'db.us.fauna.com',
        scheme: 'https',
        secret: user?.faunaSecret,
      })
    : null

  useEffect(() => {
    if (!client) return

    const fetchData = async () => {
      try {
        const res = await client.query(q.Call(q.Function('fetch_me'), [null, false]))
        setMeData(res)
      } catch (error) {
        console.error('FaunaDB Query Error:', error)
      }
    }
    fetchData()
  }, [user?.faunaSecret])

  useEffect(() => {
    if (!client) return
    if (!meData) return
    const fetchData = async () => {
      try {
        const res = await client.query(
          q.Map(
            q.Paginate(q.Match(q.Index('all_students')), { size: 99999 }),
            q.Lambda('x', q.Get(q.Var('x'))),
          ),
        )
        setStudentsData(res)
      } catch (error) {
        console.error('FaunaDB Query Error:', error)
      }
    }
    fetchData()
  }, [user?.faunaSecret, meData])

  const toddleNav = () => {
    setIsNavOpened(!isNavOpened)
  }
  const closeeNav = () => {
    setIsNavOpened(false)
  }

  if (!isFinished) {
    return <></>
  }

  return (
    <>
      <header
        className={`${
          router.asPath !== '/' && router.asPath !== '/login-error' && 'md:hidden'
        } flex-none bg-coolGray-800 flex items-center justify-center rounded-b-2xl shadow-xl`}>
        <div className="max-w-screen-lg w-full">
          <nav className="flex items-center justify-between h-16">
            <Link
              href="/"
              className="px-3 py-2 text-lg font-semibold text-white tracking-wide opacity-100">
              ニューヨーク育英学園
            </Link>
            <ul className="flex">
              {router.asPath !== '/' ? (
                <>
                  <li>
                    <button className="text-white px-2 py-2 opacity-100" onClick={toddleNav}>
                      メニュー
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a className="text-white px-2 py-2 mr-2" href="/api/login">
                      ログイン
                    </a>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </header>
      <nav
        className={`overflow-y-scroll z-50 fixed w-screen h-full min-h-screen bg-coolGray-200 ${
          !isNavOpened && 'hidden'
        }`}>
        <div className="flex flex-col justify-between overflow-y-auto my-4 mx-4">
          <div>
            <div className="text-right block">
              <span className=" text-md cursor-pointer" onClick={closeeNav}>
                閉じる
              </span>
            </div>
            <span className="text-sm text-gray-700 mt-8 mb-2 block">学籍一覧</span>
            <ul className="mb-16">
              {!studentsData && <span>Loading...</span>}
              {studentsData?.data.map((student) => {
                const { name, _id, isActive } = student
                if (isActive !== true) return
                return (
                  <li key={_id} className="mb-4">
                    <Link
                      href={`/student/${_id}`}
                      className={`${router.asPath.includes(_id) && 'nav-active '}`}
                      onClick={closeeNav}>
                      {name}
                    </Link>
                  </li>
                )
              })}
            </ul>

            {meData && (
              <ul className="mb-16">
                <li className="mb-12">
                  <Link
                    href="/register"
                    className={`${router.asPath === '/register' && 'nav-active '}`}>
                    幼児・児童・生徒登録
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href="/invoices"
                    className={`text-coolGray-700 hover:underline hover:font-semibold ${
                      router.asPath === '/invoices' && 'font-semibold text-coolGray-900'
                    }`}>
                    請求書一覧・支払い
                  </Link>
                </li>
              </ul>
            )}

            <ul className="mb-16">
              <li className="mb-4">
                <Link
                  href="https://docs.google.com/presentation/d/1avCeeWjYOmbWkJl9XG3bTrp8ePn5q8cEbVHQjseagsg"
                  target="_blank">
                  出願方法
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  href="http://bit.ly/3IyHBpb"
                  target="_blank"
                  className={'text-coolGray-700 hover:underline hover:font-semibold'}>
                  サマースクール申込み方法
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  href="/downloads"
                  className={`${router.asPath === '/downloads' && 'nav-active '}`}>
                  ファイルダウンロード
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <ul>
              <li className="mb-6">
                <Link
                  href="/profile"
                  className={`${router.asPath === '/profile' && 'nav-active '} `}>
                  保護者情報の変更
                </Link>
              </li>
              <li>
                <a href="/api/logout" className="block mb-6">
                  ログアウト
                </a>
              </li>
            </ul>
            <span className="text-gray-500 block py-2 text-xs">
              &copy; Japanese Children&apos;s Society, Inc.
            </span>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
